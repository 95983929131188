





































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import EditMixin from "@/mixins/editMixin";
import RulesMixin from "#/mixins/rulesMixin";
import PatientMixin from "@/mixins/patientMixin";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import DateInput from "#/components/DateInput.vue";
import {
  MedicalInsuranceHighExpense,
  MedicalInsuranceTokuteiDisease,
  DefaultMedicalInsuranceTokuteiDisease,
  TokuteiDiseaseDiv,
  MedicalInsuranceOldAge,
} from "#/model/patient";
import * as appDate from "#/utility/appDate";
import { PATIENT } from "#/const";

@Component({
  components: {
    PatientHeader,
    DateInput,
  },
})
export default class InsuranceInfoTokuteiDisease extends Mixins(
  AxiosMixin,
  EditMixin,
  RulesMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private insTokuteiDiseaseId = 0;
  private patientId = 0;
  private mode = "";

  /** 特定疾病受領者証 */
  private tokuteiDisease: MedicalInsuranceTokuteiDisease =
    DefaultMedicalInsuranceTokuteiDisease();

  /** 認定証の種類 */
  private divs: Choice[] = [];

  /** 自己負担限度額額の種類 */
  private selfBearLimitAmountDiv: Choice[] = [];

  /** 選んでいる疾病の種類 1: 2: */
  private selectDiv = 1;

  /** 医療保険(開始日の遅い順) */
  private highExpenses = false;

  /** 疾病区分の選択チェック */
  private checkTokuteiDiv() {
    const isSelectingTokuteiDiv = this.divs.some((tokuteiDiv) => {
      return tokuteiDiv.value === this.tokuteiDisease.tokutei_disease_div_id;
    });
    return isSelectingTokuteiDiv || "必須です";
  }

  created() {
    this.insTokuteiDiseaseId = Number(this.$route.params.instokuteidiseaseid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchTokuteiDisease();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list",
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=TokuteiDisease`,
      },
      {
        text: "特定疾病受領者証",
        disabled: true,
        to: "",
      },
    ]);
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 登録 */
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    // 疾病名、年齢、限度額でおかしい組み合わせを弾く
    if (!this.checkValidTokuteiDisease(this.tokuteiDisease)) {
      await this.$openAlert("疾病名と限度額の組み合わせがおかしいです。");
      return;
    }

    // 限度額適用認定証がない場合に確認のメッセージを出す
    if (
      !this.highExpenses &&
      this.tokuteiDisease.tokutei_disease_div_id == 10 &&
      this.tokuteiDisease.self_bear_limit_amount == 20000
    ) {
      await this.$openAlert(
          "限度額認定証との組み合わせが不適切ですが登録しますか？"
        );
    }

    // 未選択は限度額を自動選択する
    if (this.tokuteiDisease.self_bear_limit_amount == 0) {
      if (this.tokuteiDisease.tokutei_disease_div_id == 10) {
        // 慢性腎不全
        if (this.isTokuteiDiseaseOverAge(this.tokuteiDisease.start_date, 70)) {
          this.tokuteiDisease.self_bear_limit_amount = 10000;
        } else {
          //
        }
      } else {
        this.tokuteiDisease.self_bear_limit_amount = 10000;
      }
    }

    if (this.mode) {
      this.tokuteiDisease.id = 0;
    }
    this.tokuteiDisease.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/tokuteidisease/save",
      {
        tokutei_disease: this.tokuteiDisease,
      },
      async (res) => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  /** 変更 */
  private update() {
    this.tokuteiDisease.id = this.insTokuteiDiseaseId;
    this.save();
  }

  /** 削除 */
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.tokuteiDisease.id = this.insTokuteiDiseaseId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/tokuteidisease/delete",
        { tokutei_disease: this.tokuteiDisease },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 特定疾病受領者証を取得 */
  private fetchTokuteiDisease() {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/tokuteidisease/get",
      {
        patient_id: this.patientId,
        ins_tokutei_disease_id: this.insTokuteiDiseaseId,
      },
      (res) => {
        this.divs = res.data.divs;
        this.selfBearLimitAmountDiv = res.data.self_bear_limit_amount_divs;
        this.highExpenses = res.data.is_high_exp_exsist;
        /** 編集する特定疾病受領者証 */
        const tokuteiDisease: MedicalInsuranceTokuteiDisease =
          res.data.tokutei_disease;
        if (tokuteiDisease) {
          if (this.mode) {
            // コピー作成時、期間は空にする
            tokuteiDisease.start_date = "";
            tokuteiDisease.end_date = "";
          }
          this.tokuteiDisease = tokuteiDisease;
        } else {
          this.tokuteiDisease = DefaultMedicalInsuranceTokuteiDisease();
        }

        this.setLoaded();
      }
    );
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(): boolean | string {
    const startDate = this.newDate(this.tokuteiDisease.start_date);
    const endDate = this.newDate(this.tokuteiDisease.end_date);
    //高額療養費を作成する場合
    if (this.tokuteiDisease.is_make_high_expense) {
      const target = this.newDate("2018-08-01");
      if (target > startDate) {
        return "高額療養費への登録は有効期限（開始日）が平成30年8月1日(2018年8月1日)以降の場合に可能です";
      }
    }
    //開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(): boolean | string {
    const startDate = this.newDate(this.tokuteiDisease.start_date);
    const endDate = this.newDate(this.tokuteiDisease.end_date);
    //終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }

  /** 登録時バリデーション */
  private checkValidTokuteiDisease(
    tokutei: MedicalInsuranceTokuteiDisease
  ): boolean {
    // 慢性腎不全
    if (tokutei.tokutei_disease_div_id == 10) {
      if (this.isTokuteiDiseaseOverAge(tokutei.start_date, 70)) {
        return tokutei.self_bear_limit_amount != 20000;
      } else {
        return true;
      }
    }
    // 慢性腎不全以外で限度額2万円はない
    if (
      tokutei.tokutei_disease_div_id != 10 &&
      tokutei.self_bear_limit_amount == 20000
    ) {
      return false;
    }
    return true;
  }

  private requiredInsurerNo(insurer_no: string): boolean | string {
    return this.required(insurer_no, "必須です");
  }

  // 特定疾病の70歳判定のロジック
  protected isTokuteiDiseaseOverAge(targetDate: string, minAge: number) {
    if (targetDate === "") {
      targetDate = this.dateToStr(new Date(), "yyyy-MM-dd");
    }
    const rawBirthday = this.strToDate(this.patientInfo.bday);
    // 誕生日が1日以外の場合は翌月1日と考える
    const birthday = this.addMonthAndSetToFirst(rawBirthday);
    const bMonth = birthday.getMonth();
    const patientMinAgeDay = birthday;
    patientMinAgeDay.setFullYear(patientMinAgeDay.getFullYear() + minAge);
    // 閏年の2月29日から、minAge年後の3月1日になってしまった場合、みなし誕生日の2月28日に補正する
    if (patientMinAgeDay.getMonth() !== bMonth) {
      patientMinAgeDay.setDate(0);
    }
    return targetDate >= this.dateToStr(patientMinAgeDay, "yyyy-MM-dd");
  }

  /** 指定した日付が1日以外の場合 翌月1日に変換 */
  protected addMonthAndSetToFirst(birthday: Date): Date {
    const newDate = new Date(birthday);
    // 1日以外の場合は翌月1日に設定
    if (newDate.getDate() !== 1) {
      newDate.setMonth(newDate.getMonth() + 1);
      newDate.setDate(1);
    }
    return newDate;
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("tokuteiDisease", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
