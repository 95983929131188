















import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { TokuteiDiseaseItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class TokuteiDisease extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 限度額適用認定証 */
  @Prop({ default: () => [] }) private readonly items!: TokuteiDiseaseItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 限度額適用認定証ヘッダー */
  private tokuteiDiseaseItemsHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    {
      text: "疾病",
      value: "div_text",
      width: "9rem"
    },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    { text: "疾病名", value: "div_text", width: "120px" }
  ];

  /** 新規作成 */
  private tokuteiDiseaseItemsCreate() {
    this.$router.push({
      name: "InsuranceInfoTokuteiDisease",
      params: { id: String(this.patientId), instokuteidiseaseid: "0" }
    });
  }

  /** 修正 */
  private tokuteiDiseaseItemsRepair(item: TokuteiDiseaseItems) {
    this.$router.push({
      name: "InsuranceInfoTokuteiDisease",
      params: { id: String(this.patientId), instokuteidiseaseid: String(item.id) }
    });
  }

  /** コピー */
  private tokuteiDiseaseItemsCopy(item: TokuteiDiseaseItems) {
    this.$router.push({
      name: "InsuranceInfoTokuteiDisease",
      params: { id: String(this.patientId), instokuteidiseaseid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  /** 現在有効な限度額適用認定証に色を付ける */
  private tokuteiDiseaseItemsItemsRowBackground(item: TokuteiDiseaseItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
