var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-header",class:{ 'no-offset': _vm.IsFocusingMode }},[_c('div',{ref:"header",staticClass:"header"},[(!_vm.IsFocusingMode)?_c('AppBreadcrumbs',{attrs:{"items":_vm.BreadItems}}):_vm._e(),_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":"","align":"center"}},[(_vm.showLinkNextPatient && !_vm.IsFocusingMode)?[_c('div',{staticClass:"mt-1 mr-1",staticStyle:{"cursor":"pointer"},on:{"click":_vm.clickLeft}},[_c('AppIcon',{staticStyle:{"padding":"1px"},attrs:{"icon":"select-left"}})],1),_c('div',{staticClass:"mt-1 mr-1",staticStyle:{"cursor":"pointer"},on:{"click":_vm.clickRight}},[_c('AppIcon',{staticStyle:{"padding":"1px"},attrs:{"icon":"select-right"}})],1)]:_vm._e(),_c('AppTitle',{staticClass:"mr-4",attrs:{"label":_vm.info.name}}),_c('span',{staticClass:"mr-4 hidden-xs-only"},[_vm._v(_vm._s(_vm.birthDay))]),_c('AppButton',{staticClass:"mr-2",style:({ backgroundColor: _vm.$vuetify.theme.themes.light.white }),attrs:{"outlined":"","height":"40px","width":"40px"},on:{"click":function($event){return _vm.patientInfoDialog.open()}}},[_c('AppIcon',{attrs:{"icon":"detail"}})],1),_c('AppButton',{staticClass:"mr-4",style:({ backgroundColor: _vm.$vuetify.theme.themes.light.white }),attrs:{"outlined":"","height":"40px","width":"40px"},on:{"click":function($event){return _vm.patientMemoDialog.open()}}},[_c('AppIcon',{attrs:{"icon":"chat-1"}})],1),(
          _vm.info.is_med ||
            _vm.info.is_emargency ||
            (_vm.isHonobonoAgreement && !_vm.info.is_honobono) ||
            _vm.info.group_icons.length > 0 ||
            _vm.info.covid19_vaccine_status_icon.path
        )?_c('span',{staticClass:"icon-area"},[(_vm.info.is_med)?_c('AppToolTip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mx-1 text-body-1 font-weight-bold",attrs:{"color":"deep-orange darken-1","label":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" 医 ")])]}}],null,false,1108525520)},[_c('span',[_vm._v("現在有効な医療保険が登録されていません（現在有効な介護保険は登録されています）")])]):_vm._e(),(_vm.info.is_emargency)?_c('AppToolTip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mx-1 text-body-1 font-weight-bold",attrs:{"color":"blue lighten-1","label":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" 急 ")])]}}],null,false,3192558875)},[_c('span',[_vm._v("現在有効な指示書に「24時間対応体制加算(医療)」もしくは「緊急時訪問看護加算(介護)」が登録されています")])]):_vm._e(),(_vm.isHonobonoAgreement && !_vm.info.is_honobono)?_c('v-chip',{staticClass:"px-1 mx-1 text-body-1 font-weight-bold",attrs:{"color":"green darken-1","label":"","dark":""}},[_vm._v(" 未連携 ")]):_vm._e(),_vm._l((_vm.info.group_icons),function(icon,idx){return _c('AppToolTip',{key:("group-icon-" + idx),attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-1"},'span',attrs,false),on),[(icon.path)?_c('v-img',{attrs:{"max-height":"30","max-width":"30","src":icon.path}}):_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(icon.name))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.name))])])}),(
            _vm.info.covid19_vaccine_status_icon.path &&
              _vm.agreementUserSetting.covid19_vaccine_status_display > 0
          )?_c('AppToolTip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-1"},'span',attrs,false),on),[_c('v-img',{attrs:{"max-height":"30","max-width":"30","src":_vm.info.covid19_vaccine_status_icon.path}})],1)]}}],null,false,942321108)},[_c('span',[_vm._v(_vm._s(_vm.info.covid19_vaccine_status_icon.name))])]):_vm._e()],2):_vm._e(),_c('v-spacer'),(_vm.IsFocusingMode)?_c('StateButton',{staticClass:"my-1 ml-4",attrs:{"value":true,"height":"36px"},on:{"click":_vm.externalBack}},[_vm._v("画面を閉じる")]):[(_vm.IsReceiptBtn)?_c('AppButton',{staticClass:"mr-2",style:({ backgroundColor: _vm.$vuetify.theme.themes.light.white }),attrs:{"id":"receipt","outlined":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
              name: 'PatientRecieptDetail',
              query: { id: _vm.patientId }
            })}}},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"receipt","fill":_vm.$vuetify.theme.themes.light.primary}}),_vm._v("レセプト")],1):_vm._e(),_c('AppButton',{attrs:{"id":"visit","disabled":!_vm.IsVisitBtn,"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'VisitMove' })}}},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"visit-1","fill":_vm.IsVisitBtn
                ? _vm.$vuetify.theme.themes.light.white
                : _vm.$vuetify.theme.themes.light.stroke}}),_vm._v("訪問する")],1)]],2),(!_vm.IsFocusingMode)?_c('AppTabs',{staticClass:"patient-tabs",attrs:{"color":"primary"}},_vm._l((_vm.TabItems),function(tab,index){return _c('AppTab',{key:tab.id + '-' + index,staticStyle:{"position":"relative"},attrs:{"id":'patient-tab-' + tab.id + '-' + index,"to":{ name: tab.link }}},[(tab.count !== 0)?_c('v-chip',{staticClass:"white--text px-0",style:({
            position: 'absolute',
            top: '0px',
            left: '0px',
            zIindex: '50',
            width: tab.count >= 10 ? '20px' : '16px',
            height: tab.count >= 10 ? '20px' : '16px'
          }),attrs:{"color":"#e91e63"}},[_vm._v(_vm._s(tab.count))]):_vm._e(),_vm._v(" "+_vm._s(tab.title))],1)}),1):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_vm._t("default")],2),_c('PatientInfoDialog',{ref:"patientInfoDialog"}),_c('PatientMemoDialog',{ref:"patientMemoDialog",attrs:{"patientId":Number(_vm.patientId)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }