


























































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Med from "@/components/patient/insuranceinfo/Med.vue";
import EldCert from "@/components/patient/insuranceinfo/EldCert.vue";
import HighCost from "@/components/patient/insuranceinfo/HighCost.vue";
import LimitCert from "@/components/patient/insuranceinfo/LimitCert.vue";
import Care from "@/components/patient/insuranceinfo/Care.vue";
import CareRateCert from "@/components/patient/insuranceinfo/CareRateCert.vue";
import Public from "@/components/patient/insuranceinfo/Public.vue";
import { DataTableHeader } from "vuetify/types/index";
import { AllItems } from "./type";

@Component({
  components: {
    Med,
    EldCert,
    HighCost,
    LimitCert,
    Care,
    CareRateCert,
    Public,
  },
})
export default class All extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: AllItems;

  /** メッセージ表示フラグ */
  @Prop({ default: true }) private showMessage!: boolean;

  /** 介護保険ヘッダー */
  private headers: DataTableHeader[] = [
    {
      text: "種別",
      value: "type",
      sortable: false,
      cellClass: "column-type",
      align: "start",
      width: "9.5rem",
    },
    {
      text: "開始日/終了日",
      value: "date",
      sortable: false,
      cellClass: "column-date",
      align: "start",
      width: "9.5rem",
    },
    {
      text: "内容",
      value: "info",
      sortable: false,
      cellClass: "column-info",
      align: "start",
    },
  ];

  private medTable = [
    { label: "保険種類", name: "insurance_type" },
    { label: "本人/家族", name: "insurance_div" },
    { label: "保険者番号", name: "insurer_no" },
    { label: "記号・番号", name: "insured_no" },
    { label: "枝番", name: "insured_branch" },
    { label: "資格取得年月日", name: "first_issue_date" },
    { label: "給付割合", name: "payment_rate" },
    { label: "兼高齢", name: "is_old_age_certificate" },
  ];

  private eldCertTable = [
    { label: "記号・番号", name: "insured_no" },
    { label: "負担割合", name: "payment_rate" },
  ];
  private highCostTable = [
    { label: "区分", name: "div_text" },
    { label: "特記欄", name: "special_note_name" },
  ];
  private limitCertTable = [
    { label: "認定証の種類", name: "cert_div_name" },
    { label: "適用区分", name: "limit_div_text" },
  ];
  private tokuteiDiseaseTable = [
    { label: "疾病の種類", name: "div_text" },
  ];
  private careTable = [
    { label: "保険者番号", name: "insurer_no" },
    { label: "被保険者番号", name: "insured_no" },
    { label: "要介護度", name: "care_level" },
    { label: "居宅サービス計画作成者", name: "plan_creator" },
  ];
  private publicTable = [
    { label: "区分", name: "div" },
    { label: "公費種類", name: "public_money_type" },
    { label: "負担者番号", name: "bearer_number" },
    { label: "受給者番号", name: "recipient_number" },
    { label: "備考", name: "remarks" },
  ];

  private itemClass(item: { effective: boolean }): string {
    return item.effective == true ? "green lighten-4" : "";
  }
}
