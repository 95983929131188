














import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import All from "@/components/patient/insuranceinfo/All.vue";
import Med from "@/components/patient/insuranceinfo/Med.vue";
import EldCert from "@/components/patient/insuranceinfo/EldCert.vue";
import HighCost from "@/components/patient/insuranceinfo/HighCost.vue";
import LimitCert from "@/components/patient/insuranceinfo/LimitCert.vue";
import Care from "@/components/patient/insuranceinfo/Care.vue";
import CareRateCert from "@/components/patient/insuranceinfo/CareRateCert.vue";
import Public from "@/components/patient/insuranceinfo/Public.vue";
import TokuteiDisease from "@/components/patient/insuranceinfo/TokuteiDisease.vue";
import {
  MedItems,
  EldCertItems,
  HighCostItems,
  LimitCertItems,
  CareItems,
  CareRateCertItems,
  PublicItems,
  LastMonthItems,
} from "@/components/patient/insuranceinfo/type";
import { Link } from "#/types";

@Component({
  components: {
    All,
    Med,
    EldCert,
    HighCost,
    LimitCert,
    Care,
    CareRateCert,
    Public,
    TokuteiDisease
  }
})
export default class InsuranceInfo extends Mixins(AxiosMixin, PatientMixin) {
  /** 利用者ID */
  private patientId = 0;
  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない） */
  private authDiv = 0;
  /** 医療保険 */
  private medItems: MedItems[] = [];
  /** 高齢者受給者証 */
  private eldCertItems: EldCertItems[] = [];
  /** 高額療養費設定 */
  private highCostItems: HighCostItems[] = [];
  /** 限度額適用認定証設定 */
  private limitCertItems: LimitCertItems[] = [];
  /** 介護保険 */
  private careItems: CareItems[] = [];
  /** 介護保険負担割合証 */
  private careRateCertItems: CareRateCertItems[] = [];
  /** 公費 */
  private publicItems: PublicItems[] = [];
  /** 特定疾病受領者証 */
  private tokuteiDisease: TokuteiDisease[] = [];
  /** 前月時点で有効なもの */
  private lastMonthItems: LastMonthItems = {
    med: [],
    eldCert: [],
    highCost: [],
    limitCert: [],
    care: [],
    careRateCert: [],
    public: [],
    tokuteiDisease: [],
  };

  /** コンポーネントに渡すテーブルアイテム */
  private get Items() {
    switch (this.link.key) {
      case "All":
        return this.lastMonthItems;
      case "Med":
        return this.medItems;

      case "EldCert":
        return this.eldCertItems;

      case "HighCost":
        return this.highCostItems;

      case "LimitCert":
        return this.limitCertItems;

      case "Care":
        return this.careItems;

      case "CareRateCert":
        return this.careRateCertItems;

      case "Public":
        return this.publicItems;

      case "TokuteiDisease":
        return this.tokuteiDisease;
    }
    return [];
  }

  /** 左サイドメニューアイテム */
  private links = [
    { text: "一覧で見る", key: "All" },
    { text: "医療保険", key: "Med" },
    { text: "高齢受給者証", key: "EldCert" },
    { text: "高額療養費設定\n（限度額登録）", key: "HighCost" },
    { text: "限度額適用認定証", key: "LimitCert" },
    { text: "特定疾病受給者証", key: "TokuteiDisease" },
    { text: "介護保険", key: "Care" },
    { text: "介護保険負担割合証", key: "CareRateCert" },
    { text: "公費", key: "Public" }
  ];

  /** 現在有効なメニュー */
  private link = this.links[0];

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.fetchInsurer();
  }

  mounted() {
    // 有効なリンクの切り替え
    this.changeLinkQuery();
    this.setDefaultHeader();
  }

  @Watch("$route")
  watchRoute() {
    this.changeLinkQuery();
  }

  //URLの変更で動作変更
  private changeLinkQuery() {
    if (typeof this.$route.query.key === "string") {
      const key = this.$route.query.key;
      for (const v of this.links) {
        if (v.key == key) {
          this.link = v;
        }
      }
    }
  }

  //保険情報一覧取得
  private fetchInsurer(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurances/get",
      {
        patient_id: this.patientId
      },
      res => {
        this.authDiv = res.data.auth_div;
        this.medItems = res.data.med_items ?? [];
        this.eldCertItems = res.data.eld_cert_items ?? [];
        this.highCostItems = res.data.high_cost_items ?? [];
        this.limitCertItems = res.data.limit_cert_items ?? [];
        this.careItems = res.data.care_items ?? [];
        this.careRateCertItems = res.data.care_rate_cert_items ?? [];
        this.publicItems = res.data.public_items ?? [];
        this.tokuteiDisease = res.data.tokutei_disease_items ?? [];

        this.lastMonthItems.med = res.data.last_month_items.med_items;
        this.lastMonthItems.eldCert = res.data.last_month_items.eld_cert_items;
        this.lastMonthItems.highCost =
          res.data.last_month_items.high_cost_items;
        this.lastMonthItems.limitCert =
          res.data.last_month_items.limit_cert_items;
        this.lastMonthItems.care = res.data.last_month_items.care_items;
        this.lastMonthItems.careRateCert =
          res.data.last_month_items.care_rate_cert_items;
        this.lastMonthItems.public = res.data.last_month_items.public_items;
        this.lastMonthItems.tokuteiDisease = res.data.last_month_items.tokutei_disease_items;
      }
    );
  }

  private setUrlParams({ link }: { link: Link }) {
    this.$router.push({
      query: {
        key: link.key
      }
    });
  }

  //開始日と今日の日付で比較（開始日が過去のもの表示しない）
  private compDate(start: string): boolean {
    const today = new Date();
    const startDate = new Date(start.replace(/-/g, "/"));
    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    // 開始日が今日以降かどうか
    return startDate >= todayDate;
  }
}
