import Base from "../base";

//限度額適用認定証の適用区分
export interface TokuteiDiseaseDiv {
  id: number; // ID
  cert_div: number; // 認定証の種類 1:限度額適用認定証 2:限度額適用・標準負担額減額認定証
  high_expense_term_id: number; // 限度額適用認定証期間ID 3:70歳未満 4:70歳以上
  high_expense_div_id: number; // 限度額適用認定証設定区分 1:現役並み(2018/07/31以前) 2:一般 3:低所得I 4:低所得II 9:その他 (2018/08/01以降70歳以上) 5:現役並みIII 6:現役並みII 7:現役並みI (2018/08/01以降70歳未満) 11:適用区分ア 12:適用区分イ 13:適用区分ウ 14:適用区分エ 15:適用区分オ
  high_expense_div_name: string; // 高額療養費設定区分名
  special_note_code: string; // 高額療養費区分の特記事項コード
}
export const DefaultTokuteiDiseaseDiv = (): TokuteiDiseaseDiv => ({
  id: 0,
  cert_div: 0,
  high_expense_term_id: 0,
  high_expense_div_id: 0,
  high_expense_div_name: "",
  special_note_code: ""
});

//限度額適用認定証
export interface MedicalInsuranceTokuteiDisease extends Base {
  id: number; // ID
  patient_id: number; // 利用者ID
  start_date: string; // 開始日
  end_date: string; // 終了日
  tokutei_disease_div_id: number;
  insurer_no: number;
  insured_no: number;
  self_bear_limit_amount: number;

  is_make_high_expense: number; // 高額療養費作成フラグ
}
export const DefaultMedicalInsuranceTokuteiDisease = (): MedicalInsuranceTokuteiDisease => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  insurer_no: 0,
  insured_no: 0,
  is_make_high_expense: 0,
  tokutei_disease_div_id:0,
  self_bear_limit_amount:0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
